import React from "react";
import { BsArrowRight } from "react-icons/bs";
import release1 from "../assets/release1.png";


export default function Release() {
  return (
    <div className="releases" id="faq">
      <div className="release orange">
        <div className="content">
          <h2 className="title">Holder's Benefits</h2>
          <p className="description">
          ⭐ We've made $20,000+ profit with our 85% successful betting picks! 🗞️💰
          </p>
          <p className="description">
          ⭐ Make money in less than a month!<br/><br/>
          ⭐ Every holder will get proven betting tips<br/><br/>
          ⭐ $5000 budget approximately for Platinum Card holders<br/><br/>
          ⭐ Every holder can get 2 Tickets for explicit Sport Events<br/><br/>
          ⭐ Background color determine the budget for explicit Events ($100-$5000)<br/><br/>
          ⭐ Ball Type - which special events could holder attend: Soccer World Cup, NBA Finals, Superbowl, US Open, Formula 1, Champions league<br/><br/>
          ⭐ Esports stickers - decided which Esport tournament could holders participate in and become PRO player: CSGO, LOL, FIFA, PES, NBA2K, MADDEN, FORTNITE, MLB...<br/><br/>
          ⭐ Fantasy stickers - an opportunity for holders to play fantasy leagues.<br/><br/>
          ⭐ Doxed team<br/><br/>
          ⭐ 100% gas refund for first 1000 NFT minted!<br/><br/>
          
          </p>
          {/* <a href="#" className="link">
            Check them out <BsArrowRight />
          </a> */}
        </div>
        <div className="image">
          <img src={release1} alt="release" />
          <div className="ellipse pink"></div>
        </div>
      </div>
    </div>
  );
}
