import React from "react";
import signup from "../assets/signup.png";
import "../sass/timeline.css";

export default function Roadmap() {
  return (
    <div className="signup" id="roadmap">
      <div className="container">
        <div className="content">
          <h2 className="title">ROADMAP</h2>
          <p className="description">
          DAO members could have Equity in Future projects.
          </p>
        </div>
        <div className="image-container">
          <div className="image">
            <img src={signup} alt="home image" />
          </div>
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse orange"></div>
          </div>
        </div>
      </div>
      <section className="cd-horizontal-timeline">
        <div className="timeline">
          <div className="events-wrapper">
            <div className="events">
              <ol>
                <li><a href="#0" data-date="01/11/2010" className="selected">Minting</a></li>
                <li><a href="#0" data-date="01/11/2011">Betting Tips</a></li>
                <li><a href="#0" data-date="01/11/2012">LIVE SPORT EVENTS</a></li>
                <li><a href="#0" data-date="01/11/2013">SPECIAL LIVE SPORT EVENTS</a></li>
                <li><a href="#0" data-date="01/11/2014">ESPORTS</a></li>
                <li><a href="#0" data-date="01/11/2015">Fantasy Sport Leagues</a></li>

                {/* <li><a href="#0" data-date="01/11/2016">Basketball Esport Tournament</a></li>
                <li><a href="#0" data-date="01/06/2017">NBA start of the season</a></li>
                <li><a href="#0" data-date="01/11/2017">Soccer World Cup</a></li> */}

                <li><a href="#0" data-date="01/11/2018">CHARITIES</a></li>
                {/* <li><a href="#0" data-date="01/11/2019">Superbowl</a></li> */}
                <li><a href="#0" data-date="01/11/2020">NFTips PARTY</a></li>
                <li><a href="#0" data-date="01/05/2021">DAO</a></li>
                
              </ol>
              <span className="filling-line" aria-hidden="true" />
            </div> {/* .events */}
          </div> {/* .events-wrapper */}
          <ul className="cd-timeline-navigation">
            <li><a href="#0" className="prev inactive">Prev</a></li>
            <li><a href="#0" className="next">Next</a></li>
          </ul> {/* .cd-timeline-navigation */}
        </div> {/* .timeline */}
        <div className="events-content">
          <ol>
            <li className="selected" data-date="01/11/2010">
              <h2>Minting</h2>
              
              <p>
              Soon	
              </p>
            </li>
            <li data-date="01/11/2011">
              <h2>Betting Tips</h2>
            
              <p>	
              Dedicated team of sports enthusiasts analyzing the betting boards and providing insightful picks.<br/><br/>

              Holders will get betting tips each day through Year 1. The odds of their tips would be determined by the Draft Round that their NFT card contains (I, II, III, IV, or V). The odds could range from x2 to even more than x20. In this way our holder could return their investment in our project in less than a month!
              </p><br />
              <p> </p>
            </li>
            <li data-date="01/11/2012">
              <h2>LIVE SPORT EVENTS - 2xTickets</h2>
              
              <p>	
              Holders are eligible to choose any LIVE SPORTING EVENT that they want to go on!<br/><br/>

              Type of the ball on your NFT card would determine on which sporting event are you eligible to go:<br/>
              Soccer, Basketball, Hockey, Baseball, Football, Tennis<br/><br/>

              Background color (Black, Bronze, Silver, Gold, Platinum) of your NFT card would determine amount of $ that you could use for your tickets.
              </p>
            </li>
            <li data-date="01/11/2013">
              <h2>SPECIAL LIVE SPORT EVENTS - 2xTickets</h2>
             
              <p>
              Holders of Platinum NFT Cards are eligible for any of these events or their favorite event:<br/><br/>

              SOCCER -World Cup<br/>
              BASKETBALL - NBA PLAY-OFFs<br/>
              FOOTBALL - Superbowl<br/>
              F1 - Race<br/>
              Tennis - Grand slams
              </p>
            </li>
            <li data-date="01/11/2014">
              <h2>ESPORTS</h2>
              
              <p>	
              Holders of Esports stickers (FIFA, PES, NBA2K, MADDEN, LOL, CSGO, FORTNITE, and F1) would be eligible to participate on NFTips annually tournaments/leagues and win the prize!
              <br/><br/>
The best 5 players of each game would become the part of NFTips' Esport professional team!
              </p>
            </li>
            <li data-date="01/11/2015">
              <h2>Fantasy Sport Leagues</h2>
              
              <p>	
              Holders of Fantasy Stickers (Basketball, Soccer, Football, Baseball, and F1) would be eligible to participate in NFTips annually Fantasy Leagues and win the prize!
              </p>
            </li>
{/*             <li data-date="01/11/2016">
              <h2>Basketball Esport Tournament</h2>
              
              <p>	
                
              </p>
            </li>
            <li data-date="01/06/2017">
              <h2>NBA start of the season</h2>
              
              <p>	
                
              </p>
            </li>
            <li data-date="01/11/2017">
              <h2>Soccer World Cup</h2>
              
              <p>	
                (2nd Special Event)
              </p>
            </li> */}
            <li data-date="01/11/2018">
              <h2>CHARITIES</h2>
             
              <p>	
                
              </p>
            </li>
            {/* <li data-date="01/11/2019">
              <h2>Superbowl</h2>
             
              <p>	
              (3rd Special Event)
              </p>
            </li> */}
            <li data-date="01/11/2020">
              <h2>NFTips PARTY</h2>
            
              <p>	
              All holders would be eligible for NYC and LA parties in Year 1.
              </p>
            </li>
            <li data-date="01/05/2021">
              <h2>DAO Equity</h2>
              
              <p>	
              Platinum and OG Holders will have Equity in our future projects!
              </p>
            </li>
          </ol>
        </div> {/* .events-content */}
      </section>
    </div>
  );
}


