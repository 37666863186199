import React from "react";
import Card from "./TeamCard";
import super1 from "../assets/super1.png";
import super2 from "../assets/super2.png";
import super3 from "../assets/super3.png";
import super4 from "../assets/super4.png";

export default function Team() {
  const data = [
    {
      image: super1,
      series: "",
      title: "@Ivan",
      price: "(FOUNDER)",
      tag: "A NY based Entrepreneur with backgrounds in Startups, Cryptocurrency and NFTs.",
    },
    {
      image: super2,
      series: "",
      title: "@PiToN",
      price: "(Discord Manager)",
      tag: "Pro soccer player with background in Gaming industry!",
    },
    {
      image: super3,
      series: "",
      title: "@ThorrNFT",
      price: "(Marketing Director)",
      tag: "Our Web3 marketing magician!",

    },
    {
      image: super4,
      series: "",
      title: "@dani",
      price: "(Developer/Designer)",
      tag: "One of the best Web3 developer and core part of our team.",
    },
  ];
  return (
    <div className="super-rare" id="team">
      <div className="title-container">
        <h2 className="title">Team</h2>
        {/* <p className="description">
          We have released four limited edition NFT's early which which can be
          bid on via <a href="#">OpenSea</a>.
        </p> */}
      </div>
      <div className="cards">
        {data.map(({ image, series, title, price, tag, time }, index) => (
          <Card
            image={image}
            series={series}
            title={title}
            price={price}
            tag={tag}
            time={time}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}
