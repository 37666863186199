import React from "react";
import logo from "../assets/logo.png";
import { BsFacebook, BsTwitter, BsInstagram, BsDiscord } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
export default function Footer() {
  const links = [
    {
      title: "About",
      data: ["About", "Terms", "Legal"],
    },
    {
      title: "NFT",
      data: ["OpenSea"],
    },

    {
      title: "Social",
      data: [<a href="https://twitter.com/NFTips_" target="_blank" > Twitter </a>, <a href="http://discord.gg/jnTJM67ccB" target="_blank" > Discord </a>],
    },
  ];
  const socialLink = [
    
    <a href="https://twitter.com/NFTips_" target="_blank" ><BsTwitter /></a>,
    <a href="http://discord.gg/jnTJM67ccB" target="_blank" ><BsDiscord /></a>,
    
  ];
  return (
    <footer>
      <div className="upper">
        <div className="brand-container">
          <div className="brand">
            <img src={logo} alt="logo" />
          </div>
          <p>Exclusive NFT Collection</p>
          <ul>
            {socialLink.map((link, index) => (
              <li key={index}>{link}</li>
            ))}
          </ul>
        </div>
        <div className="links">
          {links.map(({ title, data }, index) => {
            return (
              <div className="link" key={index}>
                <h4>{title}</h4>
                <ul>
                  {data.map((link, index2) => (
                    <li key={index2}>{link}</li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <div className="lower">
        <span>&copy; Copyright 2022 NFTips</span>
        {/* <span>Launching August 2022</span> */}
      </div>
    </footer>
  );
}
