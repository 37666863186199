import React from "react";
import home from "../assets/home.png";
export default function Home() {
  return (
    <div className="home" id="home">
      <div className="container">
        <div className="content">
          
          <p className="sub-title">An NFT like no other</p>
          {/* <h1 className="title">An NFT like no other</h1> */}
          <p className="description">
          Welcome to the largest NFT sporting community!<br/>
          NFTips is collection of 4,444 unique NFT cards with 4,444 different sport utilities. <br/>
          NFTips is a platform that allows you to participate in sports on both a physical and digital level. This fellowship allows everyone to experience the genuine essence of sport  belonging - from analyzing over/under odds to experiencing the World Cup Final and battling it out with your squad in a fantasy or console arena.

          </p>
          <a href="/mint" target="_blank" >
          <button>Mint</button>
          </a>
        </div>
        <div className="image-container">
          <div className="image">
            <img src={home} alt="home image" />
          </div>
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse orange"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
